<template>
    <div id="nav">
    <Header/>
  </div>
  <router-view/>
  <Footer/>
</template>

<script>
import Footer from './components/Footer.vue'
import Header from './components/Header.vue'
export default {
  name: 'HomeView',
  components: {
    Header,
    Footer
  }
}
</script>
