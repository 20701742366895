<template>
  <header>
        <div class="header-main">
            <div class="container">
                <nav id='cssmenu'>
                    <div class="logo">
                        <!-- <router-link to='/'><img src="../../public/assets/images/logo.png"></router-link> -->
                        <router-link to='/'><img src="/website/assets/images/logo.png"></router-link>
                    </div>
                    <div id="head-mobile"></div>
                    <div class="button"></div>
                    <ul>
                        <li><router-link to='/'  @click="reloadHome()">Home</router-link></li>
                        <li><a href='#'>About Us</a>
                            <ul>
                                <!-- <li  @click="reload()">Who we are?</li> -->
                                <li><router-link to='#' @click="reloadWhoweare()">Who we are?</router-link></li>
                                <li><router-link to='#' @click="reloadMission()">Our Mission</router-link></li>
                            </ul>
                        </li>
                        <li ><router-link to='#' @click="reloadDonate()">Donate</router-link></li>
                    </ul>
                </nav>
            </div>
        </div>
    </header>
</template>

<script>
export default {
    name: 'HeaderCommon', 
    methods:{
        reloadHome(){
        this.$router.push({ name: "Home" }).then(() => {
            window.location.reload();
            });
         },
         reloadWhoweare(){
        this.$router.push({ name: "WhoWeAre" }).then(() => {
            window.location.reload();
            });
         },
         reloadMission(){
        this.$router.push({ name: "Mission" }).then(() => {
            window.location.reload();
            });
         },
         reloadDonate(){
        this.$router.push({ name: "Donation" }).then(() => {
            window.location.reload();
            });
         },
    }
}
</script>

<style>

</style>