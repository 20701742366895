<template>
  <div class="load-bag" v-if="showLoader">
  <div class="lds-ripple" ><div></div><div></div></div>
  </div>
  <div class="page-area">
    <img :src="this.image" />
    <div class="breadcumb-overlay"></div>
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12">
          <div class="breadcrumb text-left">
            <div class="section-headline white-headline">
              <h3>{{ this.title }}</h3>
              <p class="mt-4">{{ this.description }}</p>
              <a
              :href="this.popup" 
                class="popup-popup btn mt-4"
                >Watch Now</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--Top End -->
  <section class="pb-5 pt-5 sect-home">
    <div class="container">
      <div class="row">
        <button
          class="tablink"
          onclick="openPage('Small', this, '3px solid green')"
          id="defaultOpen"
        >
          Watch
        </button>
        <button
          class="tablink"
          onclick="openPage('Medium', this, '3px solid green')"
        >
          About
        </button>
        <hr />
        <div class="text-center mt-4">
          <div id="Small" class="tabcontent">
            <div class="Container">
              <h3 class="Head">
                <select @change="LoadVideos($event)">
                        <option v-for="item in resultCat" :key="item" v-bind:value="item._id" >{{item.name}}</option>
                    </select>

                <a href="#extras" class="extras">Extras</a>
                <span class="Arrows"></span>
              </h3>
                <!-- items -->
                    <div class="row resources ">
                      <div id="resource-slider">
                        <button class='arrow prev'></button>
                        <button class='arrow next'></button>
                        <div class="resource-slider-frame"  v-if="this.result==''"  >
                          <img src="/website/assets/images/No_Data_Found.png" alt="">                                                
                          <!-- <img src="../../public/assets/images/No_Data_Found.png" alt="">                                                 -->
                        </div>
                        <div class="resource-slider-frame"  v-else  > 
                          <div v-for="item in result" :key="item" >
                          <div class="resource-slider-item"  v-if="item.is_video_active==true" >
                            <div class="resource-slider-inset" >
                              <div class="resource ProductBlock">
                                <div>
                                  <!-- <div class="thumb">
                                      <video class="videoPlayer2" controls controlsList="nodownload">
                                        <source :src="item.video_url[0]" >
                                      </video> 
                                    </div> -->
                                <div class="thumb">
                                <a :href="item.video_url[0]"  target="_blank" class="popup-popup">
                                  <span class="play"><i class="fa fa-play" aria-hidden="true"></i></span>
                                  <div class="overlay"></div>
                                </a>
                                <img :src="item.thumbnail[0]">
                              </div>
                            </div>
                                <div class="dur-top">
                                <h3 class="mt-3">{{ item.title}}</h3>
                                <span class="Arrows">Duration - {{item.duration}}</span>
                              </div>
                                <h4>Episode {{ item.episode_no }}</h4>
                                <p>{{ item.description }}</p>
                              </div>
                            </div>
                          </div>
                        </div>                 
                        </div>
                      </div>
                    </div>
                <!-- items -->
            </div>
            <hr />
            <!-- extras -->
            
            <!-- extras -->
            <!-- <div class="carousel"> -->
            <h4 class="skip mb-5" id="extras" style="color: black"> Extras </h4>
            <div class="row resources ">
                      <div id="resource-slider-extras">
                        <button class='arrow1 prev1'></button>
                        <button class='arrow1 next1'></button>
                        <div class="resource-slider-frame-extra"  v-if="this.resultExtra==''"  >
                          <img src="/website/assets/images/No_Data_Found.png" alt="">                                                
                          <!-- <img src="../../public/assets/images/No_Data_Found.png" alt="">                                                 -->
                        </div>
                        <div class="resource-slider-frame-extra"  v-else  > 
                          <div class="resource-slider-item-extra" v-for="item in resultExtra" :key="item" >
                            <div class="resource-slider-inset-extra">
                              <div class="resource ProductBlock">
                                <div>
                                  <div class="thumb">
                                      <!-- <video class="videoPlayer2" controls controlsList="nodownload">
                                        <source :src="item.video[0]" >
                                      </video>  -->
                                      <a :href="item.video[0]" target="_blank"  class="popup-popup">
                                          <span class="play"><i class="fa fa-play" aria-hidden="true"></i></span>
                                          <div class="overlay"></div>
                                        </a>
                                        <img :src="item.thumbnail[0]">
                                    </div>
                                </div>
                                <div class="dur-top">
                                <h3 class="mt-3">{{ item.title}}</h3>
                                <span class="Arrows">Duration - {{item.duration}}</span>
                              </div>
                              </div>
                            </div>
                          </div>                 
                        </div>
                      </div>
                    </div>
<!-- </div> -->
          </div>
          <div id="Medium" class="tabcontent">
            <div class="row">
              <div class="col-xl-6 col-sm-6 col-md-6 col-lg-6">
                <h3>Overview</h3>
                <p>{{ this.overview }}</p>
              </div>
              <div class="col-xl-6 col-sm-6 col-sm-6 col-lg-6">
                <h3>Lesson</h3>
                <p>{{ this.lesson }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
  
 

<script>
import $ from 'jquery';
import axios from "../services/customAxios";
import { base_url } from "../config";
export default {
  name: "VideosModule",
  data() {
    return {
      result: [],
      resultExtra:[],
      resultCat: [],
      base_url: base_url,
      lesson: "",
      image: "",
      description: "",
      overview: "",
      title: "",
      popup:"",
      resultCatselect:"",
       showLoader: true,
    };
  },
  methods: {
    topFunction() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    async LoadShow() {
      try {
        let response = await axios.get(
          `${base_url}/get_show/` + this.$route.params._id
        );
        console.log(">>>===Shows data===>>>", response.data.result);
        this.image = response.data.result.image[0];
        this.title = response.data.result.title;
        this.description = response.data.result.description;
        this.overview = response.data.result.overview;
        this.lesson = response.data.result.mission;
        // this.showLoader= false;
      } catch (error) {
        if (error) {
           this.showLoader= false;
          this.result = [];
        }
      }
    },
    async LoadExtras() {
      try {
        let response = await axios.get(
          `${base_url}/get_Extras_byShow/` + this.$route.params._id
        );
        console.log(">>>===Shows Extraaaaa data===>>>", response.data.result);
        this.resultExtra = response.data.result.reverse();
        // this.showLoader= false;
      } catch (error) {
        if (error) {
          //  this.showLoader= false;
          this.result = [];
        }
      }
    },
    async Loadcategory() {
      try {
        let response = await axios.get(
          `${base_url}/get_category_by_show/` + this.$route.params._id
        );
        console.log(">>>===>>category data>>===>>>", response.data.result);
        this.resultCat = response.data.result;
        this.resultCatselect = response.data.result[0]._id;

        if(this.resultCatselect){
        let responseVideo = await axios.get(
          `${base_url}/get_video_byCategory/${this.resultCatselect}`
        );
        console.log(">>>===Videos data  selecttteddd===>>>", responseVideo.data.result);
        this.result = responseVideo.data.result;
        this.popup = responseVideo.data.result[0].video_url[0];
        console.log("this.result",this.result);
        console.log("this.popup",this.popup) 
        }
      } catch (error) {
        if (error) {
          //  this.showLoader= false;
          this.resultCat = [];
        }
      }
    },
    async LoadVideos(category) {
      try {
        console.log("my category id", category.target.value);
        let response = await axios.get(
          `${base_url}/get_video_byCategory/${category.target.value}`
        );
        console.log(">>>===Videos data===>>>", response.data.result);
      //   setTimeout(() => {
      //     window.location.reload();
      //     this.result = response.data.result;
      // }, 100);
          this.result = response.data.result;
        console.log("this.result",this.result)
        this.showLoader= true;
        setTimeout(() => {
          console.log("doneResizing")
          doneResizing();
          $('#resource-slider .resource-slider-item').each(function(i) {
                    var $this = $(this),
                      left = $this.width() * i;
                      $this.css({
                      left: left
                    })
                  }); // end each
                //}); // end window resize/load
                
                var resizeId;
                $(window).resize(function() {
                    clearTimeout(resizeId);
                    resizeId = setTimeout(doneResizing, 500);
                });
           this.showLoader= false;
      }, 1000);
      } catch (error) {
        if (error) {
          //  this.showLoader= false;
          this.result = [];
        }
      }
    },
  },
  async mounted() {
    this.topFunction();
    this.Loadcategory();
    this.LoadShow();
    this.LoadExtras();
    setTimeout(() => {
        this.showLoader= false;
    }, 5000);

    document.addEventListener('play', function(e){
    var mediaElements = document.querySelectorAll('video');

    for(var i = 0; i < mediaElements.length; i++){
        if(mediaElements[i] !== e.target && !mediaElements[i].paused){
            mediaElements[i].pause();
        }
    }
}, true);

  },
};

function doneResizing() {
                  var totalScroll = $('.resource-slider-frame').scrollLeft();
                  var itemWidth = $('.resource-slider-item').width();
                  var difference = totalScroll % itemWidth;
                  if ( difference !== 0 ) {
                    $('.resource-slider-frame').animate({
                      scrollLeft: '-=' + difference
                    }, 500, function() {
                      // check arrows
                      checkArrows();
                    });
                  }
                }
function checkArrows() {
                  var totalWidth = $('#resource-slider  .resource-slider-item').length * $('.resource-slider-item').width();
                  var frameWidth = $('.resource-slider-frame').width();
                  var itemWidth = $('.resource-slider-item').width();
                  var totalScroll = $('.resource-slider-frame').scrollLeft();
                  
                  if ( ((totalWidth - frameWidth) - totalScroll) < itemWidth ) {
                    $(".next").css("visibility", "visible");
                  }
                  
                  else {
                    $(".next").css("visibility", "visible");
                  }
                  if ( totalScroll < itemWidth ) {
                    $(".prev").css("visibility", "visible");
                  }
                  else {
                    $(".prev").css("visibility", "visible");
                  }
                }

                
</script>

<style>
.videoPlayer2{
  height: 160px;
  width: 100%;
  border-radius: 10px;
  border: 1px solid rgb(163, 160, 160);
  box-shadow: 4px 4px 9px gray;
}
</style>