<template>
  <div class="load-bag" v-if="showLoader">
  <div class="lds-ripple" ><div></div><div></div></div>
  </div>
  <div class="page-area">
        <!-- <img src="../../public/assets/images/banner-image-new.jpg"><div class="breadcumb-overlay"> -->
          <img src="/website/assets/images/banner-image-new.jpg"><div class="breadcumb-overlay">

    
</div>
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-sm-12 col-xs-12">
                    <div class="breadcrumb text-center">
                        <div class="section-headline white-headline text-center">
                            <h3>Privacy Policy</h3>
                        </div>
                        <ul>
                          <li class="home-bread"><router-link to="/" style="color: #fff">Home</router-link> </li>
                            <li>Privacy Policy</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
<section class="mb-5 privacy-policy">
    <div class="container">
       
           <div class="six">
             <h2 class="mt-5 text-black" ref="dynamicText"> {{viewtitle}}</h2>
            </div>
        <div class="row mt-5" >
            <p ref="dynamicText" v-html="viewdescription"></p>
        </div>
        <!-- <a href="#" @click="downloadPDF" id="tableExport" class="privacy-btn">Download PDF</a> -->
    </div>
</section>
  </template>
  
  
<script>
import html2pdf from 'html2pdf.js';
import axios from "../services/customAxios";
import { base_url } from "../config";
export default {
 name: 'PrivacyPolicy',
 data(){
   return{
     result:[],       
     base_url: base_url,
     viewtitle:"",       
     viewdescription:"",       
     showLoader: true,

    }
 },
 methods:{
  async downloadPDF() {
      // Get the dynamic text content element
      const dynamicTextElement = this.$refs.dynamicText;
      console.log(dynamicTextElement,dynamicTextElement)

      // Create an HTML2PDF options object
      const options = {
        margin: 10,
        filename: 'Privacy_policy.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
      };
      console.log("options",options)
      // Use html2pdf to generate and download the PDF
      const pdf = await html2pdf().from(dynamicTextElement).outputPdf();

      // Create a Blob from the PDF data
      const blob = new Blob([pdf], { type: 'application/pdf' });

      // Create a link element and trigger a download
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'Privacy_policy.pdf';
      link.click();
    },
  topFunction() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
   async LoadShow() {
     try{
     let response = await axios.get(`${base_url}/get_all_content`); 
     console.log(">>>===>>>===>>>",response.data.result);
     this.viewdescription = response.data.result[0].description;
     this.viewtitle = response.data.result[0].title;
     this.showLoader= false;

   }
   catch (error) {
     if (error) {
       this.showLoader= false;
         this.result = [];
       }
     }
   }     
 },
 async mounted(){
   this.LoadShow();
   this.topFunction();
 }
};
</script>
  
  <style>
  
  </style>