import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import WhoWeAre from '../views/WhoWeAre.vue'
import Mission from '../views/Mission.vue'
import Donation from '../views/Donation.vue'
import Videos from '../views/Videos.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'
import TermsAndConditions from '../views/TermsAndConditions.vue'
import FAQ from '../views/FAQ.vue'
import HelpCenter from '../views/HelpCenter.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/who-we-are',
    name: 'WhoWeAre',
    component: WhoWeAre
  },
  {
    path: '/our-mission',
    name: 'Mission',
    component: Mission
  },
  {
    path: '/donation',
    name: 'Donation',
    component: Donation
  },
  {
    path: '/videos/:_id',
    name: 'Videos',
    component: Videos
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy
  },
  {
    path: '/terms-and-conditions',
    name: 'TermsAndConditions',
    component: TermsAndConditions
  },
  {
    path: '/faqs',
    name: 'FAQ',
    component: FAQ
  },
  {
    path: '/help',
    name: 'HelpCenter',
    component: HelpCenter
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
