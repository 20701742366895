<template>
  <footer class="site-footer">
    <div class="container">
            
        <div class="site-footer__middle">
            <div class="row">
                <div class="col-xl-4 col-lg-4 col-md-4 wow fadeInUp" data-wow-delay="200ms">
                    <div class="footer-widget__column footer-widget__link">
                        <div class="footer-widget__title-box">
                            <h3 class="footer-widget__title">About Us</h3>
                        </div>
                        <ul class="footer-widget__link-list list-unstyled">
                           <li><router-link to="/who-we-are">Who we are?</router-link></li>
                            <li><router-link to="/our-mission"> Our Mission</router-link></li>
                        </ul>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4 wow fadeInUp" data-wow-delay="200ms">
                    <div class="footer-widget__column footer-widget__link">
                        <div class="footer-widget__title-box">
                            <h3 class="footer-widget__title">Legal</h3>
                        </div>
                        <ul class="footer-widget__link-list list-unstyled">
                          <li><router-link to="/privacy-policy">Privacy Policy</router-link></li>
                            <li><router-link to="/terms-and-conditions">Terms & Conditions</router-link></li>
                            <li><router-link to="/faqs">FAQ's</router-link></li>
                            <li><router-link to="/help">Help Center</router-link></li>
                         
                        </ul>
                    </div>
                </div>
            
             
                <div class="col-xl-4 col-lg-4 col-md-4 wow fadeInUp" data-wow-delay="500ms">
                    <div class="footer-widget__column footer-widget__Contact">
                        <div class="footer-widget__logo">
                            <!-- <router-link to="/"><img src="../../public/assets/images/logo-white.png" alt=""></router-link> -->
                        <router-link to='/'><img src="/website/assets/images/logo-white.png"></router-link>
                        </div>
                        <div class="site-footer__social">
                            <router-link to="#" target="_blank" id="linkedin-link"><i class="fa fa-linkedin"></i></router-link>
                            <router-link to="#" target="_blank" id="twitter-link"><i class="fa-brands fa-x-twitter"></i></router-link>
                            <router-link to="#" target="_blank" id="instagram-link"><i class="fa fa-instagram"></i></router-link>
                        </div>
                       <div>
                        <p class="site-footer__bottom-text mt-3">© Copyright 2023 by <router-link to="#">Video Sharing website</router-link></p>
                       </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</footer>
</template>

<script>
import axios from "../services/customAxios";
import { base_url } from "../config";
export default {
 name: 'FooterCommon',
 data(){
   return{
     result:[],       
     base_url: base_url,
     instalink:"",       
     linkedinlink:"",       
     twitterlink:"",       
     showLoader:true,
    }
 },
 methods:{
   async LoadShow() {
     try{
     let response = await axios.get(`${base_url}/get_all_social`); 
     console.log(">>>===>>>===>>>",response.data.result);
     this.instalink = response.data.result[0].link;
     this.linkedinlink = response.data.result[1].link;
     this.twitterlink = response.data.result[2].link;
     console.log(">>>===>>>===>>>", this.instalink, this.linkedinlink,this.twitterlink  );
     this.showLoader= false;

     document.getElementById('linkedin-link').href = this.linkedinlink;
     document.getElementById('twitter-link').href = this.twitterlink;
     document.getElementById('instagram-link').href = this.instalink;
   }
   catch (error) {
     if (error) {
       this.showLoader= false;
         this.result = [];
       }
     }
   }     
 },
 async mounted(){
   this.LoadShow();
 }
};
</script>
