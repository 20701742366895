<template>
  <div class="load-bag" v-if="showLoader">
  <div class="lds-ripple" ><div></div><div></div></div>
  </div>
  <div class="page-area">
        <!-- <img src="../../public/assets/images/banner-image-new.jpg"><div class="breadcumb-overlay"> -->
          <img src="/website/assets/images/banner-image-new.jpg"><div class="breadcumb-overlay">

    
</div>
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-sm-12 col-xs-12">
                    <div class="breadcrumb text-center">
                        <div class="section-headline white-headline text-center">
                            <h3>Our Mission</h3>
                        </div>
                        <ul>
                            <li class="home-bread">About Us</li>
                            <li>Our Mission</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
<section class="pb-5 pt-5 sect-home">
    <div class="container">
        
        <div class="six">
            <h2 class="text-center">{{viewtitle}}</h2>
            </div>
           <div class="text-center mt-4">
           <div id="Medium">
            <div class="row">
                
                <div class="col-xl-12 col-sm-12 col-sm-12 col-lg-12">
                    <p class="text-white" v-html="viewdescription"></p>
                </div>
            </div>
       
        </div>
            </div>
      
    </div>
</section>
</template>

  
<script>
import axios from "../services/customAxios";
import { base_url } from "../config";
export default {
 name: 'OurMission',
 data(){
   return{
     result:[],       
     base_url: base_url,
     viewtitle:"",       
     viewdescription:"",  
     showLoader:true,
    }
 },
 methods:{
  topFunction() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
   async LoadShow() {
     try{
     let response = await axios.get(`${base_url}/get_all_content`); 
     console.log(">>>===>>>===>>>",response.data.result);
     this.viewdescription = response.data.result[2].description;
     this.viewtitle = response.data.result[2].title;
     this.showLoader= false;
   }
   catch (error) {
     if (error) {
       this.showLoader= false;
         this.result = [];
       }
     }
   }     
 },
 async mounted(){
   this.LoadShow();
   this.topFunction()
 }
};
</script>
<style>

</style>