<template>
  <div class="load-bag" v-if="showLoader">
  <div class="lds-ripple" ><div></div><div></div></div>
  </div>
  <div class="page-area">
    <!-- <img src="../../public/assets/images/banner-image-new.jpg"><div class="breadcumb-overlay"> -->
        <img src="/website/assets/images/banner-image-new.jpg"><div class="breadcumb-overlay">

    
</div>
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-sm-12 col-xs-12">
                    <div class="breadcrumb text-center">
                        <div class="section-headline white-headline text-center">
                            <h3>FAQ's</h3>
                        </div>
                        <ul>
                          <li class="home-bread"><router-link to="/" style="color: #fff">Home</router-link> </li>
                            <li>FAQ's</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
  <section class="pb-5 pt-5 sect-home">
    <div class="container">
      <div class="six">
        <h2 class="text-center">Frequently Asked Questions</h2>
      </div>

      <div class="row">
        <div class="text-center mt-4">
          <div id="Medium">
            <div class="row">
              <div class="col-xl-12 col-sm-12 col-sm-12 col-lg-12">
                <div class="accordion">
                  <div
                    class="accordion-item"
                    v-for="(item, index) in result"
                    :key="index"
                  >
                    <button
                      :id="'accordion-button-' + (index + 1)"
                      aria-expanded="false"
                      @click="toggleAccordion(index)"
                    >
                      <span class="accordion-title">{{ item.que }}</span>
                      <span class="icon" aria-hidden="true"></span>
                    </button>
                    <div class="accordion-content">
                      <p>{{ item.ans }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
  
 
<script>
import axios from "../services/customAxios";
import { base_url } from "../config";
export default {
  name: "FrequentlyAskedQuestions",
  data() {
    return {
      result: [],
      base_url: base_url,
      activeIndex: null,
     showLoader:true,
    };
  },
  methods: {
    async LoadShow() {
      try {
        let response = await axios.get(`${base_url}/get_all_faq_user`);
        console.log(">>>===>>>===>>>", response.data.result);
        this.result = response.data.result.reverse();
        this.showLoader= false;
      } catch (error) {
        if (error) {
           this.showLoader= false;
          this.result = [];
        }
      }
    },
    topFunction() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    toggleAccordion(index) {
      const buttons = document.querySelectorAll(".accordion button");
      buttons.forEach((button, i) => {
        if (i === index) {
          const expanded = button.getAttribute("aria-expanded") === "true";
          button.setAttribute("aria-expanded", String(!expanded));
        } else {
          button.setAttribute("aria-expanded", "false");
        }
      });
    },
  },
  async mounted() {
    this.LoadShow();
    this.topFunction();
  },
};
</script>
  