<template>
  <div class="load-bag" v-if="showLoader">
  <div class="lds-ripple" ><div></div><div></div></div>
  </div>
    <div class="page-area">
        <!-- <img src="../../public/assets/images/banner-image-new.jpg"><div class="breadcumb-overlay"> -->
          <img src="/website/assets/images/banner-image-new.jpg"><div class="breadcumb-overlay">

    
</div>
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-sm-12 col-xs-12">
                    <div class="breadcrumb text-center">
                        <div class="section-headline white-headline text-center">
                            <h3>Terms & Conditions</h3>
                        </div>
                        <ul>
                          <li class="home-bread"><router-link to="/" style="color: #fff">Home</router-link> </li>
                            <li>Terms & Conditions</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <section class="mb-5 privacy-policy">
        <div class="container">
            <div class="six">
                <h2 class="mt-5 text-black">{{viewtitle}}</h2>
            </div>
           
            <div class="row mt-5" >
                <p v-html="viewdescription"></p>
            </div>
            <!-- <a href="#" class="privacy-btn">Download PDF</a> -->
        </div>
    </section>
  </template>
  
 
<script>
import axios from "../services/customAxios";
import { base_url } from "../config";
export default {
 name: 'TermsAndConditions',
 data(){
   return{
     result:[],       
     base_url: base_url,
     viewtitle:"",       
     viewdescription:"",  
     showLoader: true,
    }
 },
 methods:{
  topFunction() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
   async LoadShow() {
     try{
     let response = await axios.get(`${base_url}/get_all_content`); 
     console.log(">>>===>>>===>>>",response.data.result);
     this.viewdescription = response.data.result[1].description;
     this.viewtitle = response.data.result[1].title;
     this.showLoader= false;
  
   }
   catch (error) {
     if (error) {
       this.showLoader= false;
         this.result = [];
       }
     }
   }     
 },
 async mounted(){
   this.LoadShow();
   this.topFunction()
 }
};
</script>

<style>

</style>