<template>
  <div class="load-bag" v-if="showLoader">
  <div class="lds-ripple" ><div></div><div></div></div>
  </div>
  <div class="page-area">
        <!-- <img src="../../public/assets/images/banner-image-new.jpg"><div class="breadcumb-overlay"> -->
        <img src="/website/assets/images/banner-image-new.jpg"><div class="breadcumb-overlay">
</div>
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-sm-12 col-xs-12">
                    <div class="breadcrumb text-center">
                        <div class="section-headline white-headline text-center">
                            <h3>Donation</h3>
                        </div>
                        <ul>
                            <li class="home-bread"><router-link to="/" style="color: #fff">Home</router-link> </li>
                            <li>Donation</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
  <section class="pb-5 pt-5 sect-home">
    <div class="container">
      <div class="row">
        <div class="col-md-4">
          <h2 class="mb-5">{{viewtitile}}</h2>

          <p class="text-white">
            {{viewdescription}}
          </p>
          <div class="address mb-3">
            <h4><i class="fa fa-map-marker" aria-hidden="true"></i> Address</h4>
            <p class="text-white">
              {{ viewname }}
            </p>
          </div>
          <div class="mailid mb-3">
            <h4>
              <i class="fa fa-mobile" aria-hidden="true"></i> Phone Number
            </h4>
            <p class="text-white">{{viewphone_number}}</p>
          </div>
          <div class="phonenu mb-3">
            <h4><i class="fa fa-envelope" aria-hidden="true"></i> Mail ID</h4>
            <p class="text-white">
              <a :href="'mailto:' + viewemail" class="text-white">
                {{viewemail}}
              </a>
            </p>
          </div>
        </div>
        <div class="col-md-8">
          <div class="six">
            <h2 class="text-center mb-2">Donation</h2>
            <p class="text-center text-white mb-5">Support our Ministry</p>
          </div>
          <form id="contactForm" class="contact-form row mt-4 mb-4">
            <div class="col-md-6 col-sm-6 col-xs-12">
              <input type="text" id="name" class="form-control" v-model="name" placeholder="Name" required="" data-error="Please enter your name"/>
              <div class="help-block with-errors"></div>
            </div>
            <div class="col-md-6 col-sm-6 col-xs-12">
              <input type="text" id="number" class="form-control" v-model="phone" placeholder="Mobile Number" required="" data-error="Please enter your mobile number" />
              <div class="help-block with-errors"></div>
            </div>
            <div class="col-md-12 col-sm-12 col-xs-12">
              <input type="text" class="email form-control" id="email" v-model="email" placeholder="Email address" required=""  data-error="Please enter your email"/>
              <div class="help-block with-errors"></div>
            </div>

            <div class="col-md-12 col-sm-12 col-xs-12">
              <textarea id="Description" rows="7" type="text" v-model="description" placeholder="Description" class="form-control" required="" data-error="Write your message" ></textarea>
              <div class="help-block with-errors"></div>
            </div>
            <div class="col-md-12 col-sm-12 col-xs-12 text-center">
              <button type="button" @click="Donation"  id="submit" class="add-btn contact-btn">
                Send Request
              </button>
              <div id="msgSubmit" class="h3 text-center hidden"></div>
              <div class="clearfix"></div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>


<script>
// import {DoubleBounce} from 'vue-loading-spinner'
import { defineComponent } from 'vue'
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'
import axios from "../services/customAxios";
import { base_url } from "../config";
export default defineComponent({
  name: "DonationForm",
//   components: {
//     DoubleBounce,
//   },
  setup () {
    const toast = () => {
        createToast()
    }
    return { toast }
  },
  data(){
    return{
        name: "",
        phone: "",
        email: "",
        description: "",
        viewname:"",
        viewphone_number:"",
        viewemail:"",
        viewtitile:"",
        viewdescription:"",
        showLoader: true,
        base_url: base_url,
    }
  },
  methods: { 
    async LoadContact() {
       try{
       let response = await axios.get(`${base_url}/get_all_contact`); 
       console.log(">>>===>>>===>>>",response.data.result);
       this.viewtitile = response.data.result[0].title;
       this.viewdescription = response.data.result[0].description;
       this.viewname = response.data.result[0].name;
       this.viewphone_number = response.data.result[0].phone_number;
       this.viewemail = response.data.result[0].email;
       this.showLoader= false;
     }
     catch (error) {
       if (error) {
         this.showLoader= false;
           this.result = [];
         }
       }
     } ,
    async Donation() {
    //   this.showLoader = true
  try{
     let response = await axios.post(`${base_url}/add_donation`, {
        name: this.name,
        phone: this.phone,
        email: this.email,
        description: this.description,
     });
    createToast({title: "Success", description: response.data.message},{type: "success"})
     if(response.data.success == true){
      this.$router.push('/')
     }
    }
    catch(error){
      this.showLoader = false
      createToast({title: "Error", description: error?.response?.data?.message},{type: "danger"})
    }
 },
  },
 async mounted(){
   this.LoadContact();
   }
});
</script>


<style>
</style>