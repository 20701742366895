<template>
  <div class="load-bag" v-if="showLoader">
  <div class="lds-ripple" ><div></div><div></div></div>
  </div>
  <div id="slider-home">
    <div class="carousel-inner">
      <div class="carousel-item active">
        <!-- <img src="../../public/assets/images/open-door-ministries.gif" alt="banner" /> -->
        <img src="/website/assets/images/open-door-ministries.gif" alt="banner" />
      </div>
    </div>
  </div>
  <section class="pb-5 pt-5 sect-home">
    <div class="container">
      <div class="heading-h2">
        <div class="six">
          <h2 class="text-center">FEATURED VIDEOS</h2>
        </div>
      </div>
      <div class="row">
        <div class="text-center mt-5">
          <div id="Small">
            <div class="read-more-wrap">
              <div class="row mb-2"  v-for="(item, index) in displayedShows" :key="index">
                <div class="col-xl-4 col-sm-4 col-md-4 col-lg-4" >
                  <div class="thumb">
                  <!-- <router-link to="#"> -->
                  <router-link @click="navigateAndReload(item._id)" :to="'/videos/' + item._id">
                      <span class="play"
                        ><i class="fa fa-play" aria-hidden="true"></i
                      ></span>
                      <div class="overlay"></div>
                    </router-link>
                    <img :src="item.image" />
                  </div>
                </div>
                <div class="col-xl-8 col-sm-8 col-sm-8 col-lg-8" >
                  <div class="video-desc small-content" >
                    <h3>{{item.title}}</h3>
                    <p>{{item.overview.substring(0, 500)}}
                      <router-link @click="navigateAndReload(item._id)" :to="'/videos/' + item._id">View more</router-link>
                    </p>
                  </div>
                </div>
                <hr />
              </div>
            </div>
            <button v-if="shouldShowViewMoreButton" class="view-more-less read-more-trigger add-btn contactt-btn mb-4" @click="toggleViewMore">View more videos</button>
            <button v-if="shouldShowViewLessButton" class="view-more-less read-more-trigger add-btn contactt-btn mb-4" @click="toggleViewLess">View less videos</button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>



<script>
  import axios from "../services/customAxios";
  import { base_url } from "../config";
  export default {
   name: 'HomeComponent',
   data(){
     return{
       result:[],       
       base_url: base_url,
       showLoader: true,
       showsPerLoad: 3, 
       numToShowInitially: 3,
      }
   },
   computed: {
    displayedShows() {
      return this.result.slice(0, this.numToShowInitially);
    },
    shouldShowViewMoreButton() {
      return this.numToShowInitially < this.result.length;
    },
    shouldShowViewLessButton() {
      return this.numToShowInitially > 3;
    },
  },
   methods:{
     async LoadShow() {
       try{
       let response = await axios.get(`${base_url}/get_all_show`); 
       console.log(">>>===>>>===>>>",response.data.result);
       this.result = response.data.result.reverse();
      this.showLoader = false;
     }
     catch (error) {
       if (error) {
         this.showLoader= false;
           this.result = [];
         }
       }
     },
     toggleViewMore() {
      this.numToShowInitially = this.result.length;
    },
    toggleViewLess() {
      this.numToShowInitially = 3;
    },
     topFunction() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },     
    navigateAndReload(itemId) {
      this.$router.push("/videos/" + itemId);
      setTimeout(() => {
        window.location.reload();
      }, 100);
    },
   },
   async mounted(){
     this.LoadShow();
     this.topFunction()
   }  
  };
  </script>
 
 <style>
 .view-more-less {
    cursor: pointer;
    display: inline-block;
    padding: 10px 20px;
    background: #141414;
    color: #fff;
    font-size: 15px;
    line-height: 2;
    border-radius: 0.25em;
}
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  width: 300px;
  height: 300px;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 100px;
    left: 100px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 100px;
    left: 100px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 100px;
    left: 100px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 200px;
    height: 200px;
    opacity: 0;
  }
}
.load-bag {
  position: fixed;
  top: 0;
  background:#0000006b;
  z-index: 999;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  display: flex;
}
</style>