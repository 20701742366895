<template>
  <div class="load-bag" v-if="showLoader">
  <div class="lds-ripple" ><div></div><div></div></div>
  </div>
  <div class="page-area">
        <!-- <img src="../../public/assets/images/banner-image-new.jpg"><div class="breadcumb-overlay"> -->
          <img src="/website/assets/images/banner-image-new.jpg"><div class="breadcumb-overlay">

    
</div>
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-sm-12 col-xs-12">
                    <div class="breadcrumb text-center">
                        <div class="section-headline white-headline text-center">
                            <h3>Who We Are</h3>
                        </div>
                        <ul>
                            <li class="home-bread">About Us</li>
                            <li>Who We Are</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
  <section class="about-section">
    <div class="container">
        <div class="row">                
            <div class="content-column col-lg-6 col-md-12 col-sm-12 order-2">
                <div class="inner-column">
                    <div class="sec-title">
                        <span class="title">About us</span>
                        <h2>{{viewtitle}}</h2>
                    </div>
                    <div class="text" v-html="viewdescription"></div>
                </div>
            </div>

            <!-- Image Column -->
            <div class="image-column col-lg-6 col-md-12 col-sm-12">
                <div class="inner-column wow fadeInLeft">
                  <div class="author-desc">
                    <h2>Video Sharing</h2>
                    <span>Share your talent</span>
                  </div>
                    <figure class="image-1"><a href="#" class="lightbox-image" data-fancybox="images">
                        <img title="who we are" :src="viewimage" alt=""></a></figure>
                </div>
            </div>
          
        </div>
     
     
    </div>
</section>
</template>


<script>
  import axios from "../services/customAxios";
  import { base_url } from "../config";
  export default {
   name: 'WhoWeAre',
   data(){
     return{
       result:[],       
       base_url: base_url,
       viewtitle:"",       
       viewdescription:"",       
       viewimage:"",
       showLoader: true,
      }
   },
   methods:{
    topFunction() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
     async LoadShow() {
       try{
       let response = await axios.get(`${base_url}/get_all_content`); 
       console.log(">>>===>>>===>>>",response.data.result);
       this.viewdescription = response.data.result[3].description;
       this.viewtitle = response.data.result[3].title;
       this.viewimage = response.data.result[3].image[0];
       this.showLoader= false;
     }
     catch (error) {
       if (error) {
         this.showLoader= false;
           this.result = [];
         }
       }
     }     
   },
   async mounted(){
     this.LoadShow();
     this.topFunction();
   }
  };
  </script>
 
<style>

</style>